<template>
  <div class="login-wrap">
    <div class="dynamic-banner">
      <img
        :src="
          product.promotionalImage
            ? product.promotionalImage
            : `/images/login/banner.png`
        "
        alt=""
      />
    </div>

    <div class="user-input block">
      <div class="title">注册信息填写</div>

      <van-form @submit="loginFn" ref="loginForm">
        <div class="input-area">
          <div class="on-input">
            <img src="/images/login/phone@2x.png" alt="" />
            <van-field
              v-model="form.mobile"
              placeholder="请输入手机号"
              maxlength="11"
            />
          </div>
          <div class="on-input verify-code">
            <img src="/images/login/verification@2x.png" alt="" />
            <van-field v-model="form.smsCode" placeholder="请输入验证码" />
            <div
              :class="['send-btn', sending ? 'sending' : '']"
              @click="getCode"
            >
              {{ sending ? `${second}s` : "发送验证码" }}
            </div>
          </div>
          <div class="agreement">
            <van-checkbox v-model="agreement" checked-color="#1C97FF">
              我已阅读并同意
              <span class="link" @click.stop="toAgreement">
                《用户协议及隐私政策》
              </span>
            </van-checkbox>
          </div>
          <van-button
            class="large-btn login-btn"
            size="large"
            :loading="loading"
            round
            color="linear-gradient(to right,#46C4FF, #1C97FF)"
            type="info"
            native-type="submit"
            >登录
          </van-button>
        </div>
      </van-form>
    </div>

    <div class="notice block">
      <div class="notice-title">须知</div>
      <div class="content">
        <div
          class="content-text"
          v-if="product.financeNotice"
          v-html="product.financeNotice"
        ></div>
        <div v-else class="none-data">暂无内容</div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  smsLgcode,
  login,
  productInfos,
  getUserInfo,
  getLastOrder
} from "@/api/login";
import { mapActions } from "vuex";
import { setToken } from "@/utils/app";
export default {
  data() {
    return {
      agreement: false,
      pageLoading: false,
      loading: false,
      sending: false,
      second: 60,
      product: {},
      form: {}
    };
  },
  created() {
    const { doordog, productNo, merchantNo } = this.$route.query;
    if (doordog) {
      if (!productNo || !merchantNo) {
        this.$router.replace(
          `/login?doordog=doordog&productNo=917764972132438016&merchantNo=503048`
        );
      } else {
        setTimeout(() => {
          this.authLogin();
        }, 1500);
      }
    }
  },
  mounted() {
    this.getProductInfos();
  },
  methods: {
    ...mapActions(["setUserInfo", "setProductInfo", "setUserOrderInfo"]),
    randomPhone() {
      const hd = ["13", "15", "17", "18", "19"];
      let str = "";
      function rd(n, r) {
        if ((arguments.length < 2 && ((r = n), (n = 0)), n > r)) {
          var a = r;
          (r = n), (n = a);
        }
        return Math.floor(Math.random() * (r - n + 1)) + n;
      }
      for (let i = 0; i < 9; i++) {
        str += rd(0, 9);
      }
      return new Promise((resp, rej) => {
        resp(hd[rd(0, 4)] + str);
      });
    },
    async authLogin() {
      const p = await this.randomPhone();
      this.$set(this.form, "mobile", p);
      await this.getCode();
      setTimeout(() => {
        const time = new Date();
        const h = time.getHours();
        const m = time.getMinutes();
        const code = `${h < 10 ? "0" + h : h}${m < 10 ? "0" + m : m}`;
        this.form.smsCode = code;
        this.agreement = true;
        this.loginFn();
      }, 500);
    },
    // 产品信息
    async getProductInfos() {
      try {
        this.pageLoading = true;
        const { merchantNo, productNo, agentNo } = this.$route.query;
        let pn = productNo;
        if (!pn) throw "产品编号错误";
        else {
          let { data } = await productInfos(pn);
          data.merchantNo = merchantNo;
          data.agentNo = agentNo;
          this.product = data || {};
          this.setProductInfo(this.product);
        }
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },
    async loginFn() {
      try {
        if (!this.agreement) {
          throw "请先阅读并同意《用户协议及隐私政策》";
        }
        if (!this.form.mobile) {
          throw "请输入手机号";
        }
        if (!this.form.smsCode) {
          throw "请输入验证码";
        }
        let params = {
          username: this.form.mobile,
          smsCode: this.form.smsCode
        };

        this.loading = true;
        const { data } = await login(params);
        // 存储token
        const token = data.tokenInfo.access_token;
        const token_type = data.tokenInfo.token_type;
        const Authorization = `${token_type} ${token}`;
        setToken(Authorization);

        this.$toast.success("登录成功");

        // 获取登录的用户信息
        const { data: userInfo } = await getUserInfo();
        this.setUserInfo(userInfo);
        await this.getLastOrder();
        this.$router.push("/apply");
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取最近订单，没有订单后端会生成预进件订单
    async getLastOrder() {
      try {
        const { merchantNo, productNo } = this.$route.query;
        const { data } = await getLastOrder(productNo, merchantNo);
        this.setUserOrderInfo(data);
        const { accessFlag, livingFlag, creditFlag } = data;
        if (!accessFlag) {
          // 准入不通过
          this.$router.push("/apply");
          return;
        }
        if (!livingFlag) {
          // 活体认证不通过
          this.$router.push("/identity-portrait");
          return;
        }
        if (!creditFlag) {
          // 未授权
          this.$router.push("/authorization");
          return;
        }
        this.$router.push("/apply-detail");
      } catch (error) {
        this.$errMsg(error);
      }
    },
    async getCode() {
      if (this.sending) return;
      const mobilePass = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.form.mobile);
      if (!mobilePass) {
        this.$toast.fail("请输入正确的手机号");
        return;
      }
      const { productNo, merchantNo } = this.$route.query;
      const params = {
        merchantNo,
        productNo
      };
      try {
        await smsLgcode(this.form.mobile, params);
        this.$toast.success("验证码已发送，请注意查收！");
        this.sending = true;
        this.itv = setInterval(() => {
          this.second--;
          if (this.second <= 0) {
            this.second = 60;
            this.sending = false;
            clearInterval(this.itv);
          }
        }, 1000);
        return Promise.resolve(true);
      } catch (error) {
        this.sending = false;
        this.$errMsg(error);
        return Promise.reject(error);
      }
    },
    toAgreement() {
      window.open("https://h5.songchewang.com/#/agreement/register");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
